
/* eslint-disable semi */
import { defineComponent, ref } from 'vue'
import AppHeaderVue from './components/AppHeader.vue';

export default defineComponent({
  components: {
    'app-header': AppHeaderVue
  },
  setup () {
    const title = ref('TİM E-İmza');

    return {
      title
    }
  }
})
