/* eslint-disable semi */
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import TitleDesc from '@/components/TitleDesc.vue';
import Card from '@/components/Card.vue';
import InstallSigner from '@/components/InstallSigner.vue';
import PrimeVue from 'primevue/config';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
// import 'primeicons/primeicons.css';

const app = createApp(App)
  .use(PrimeVue)
  .use(store)
  .use(router);

app
  .component('app-title-desc', TitleDesc)
  .component('app-card', Card)
  .component('app-install-signer', InstallSigner)
  .component('BlockUI', BlockUI)
  .component('ProgressSpinner', ProgressSpinner)

app.mixin({
  methods: {
    updateStore ($event, name: string, value) {
      name = name || $event.target.name;

      if (name) {
        this.updateApplicationData({
          [name]: value || $event.target.value
        });
      }
    },
    openUrl (url: string) {
      if (url) window.open(url);
    },
    redirect (url: string) {
      if (url) window.location.href = url;
    },
    goTo (route: string) {
      if (route) {
        if (Number.isInteger(route)) {
          this.$router.go(route);
        } else {
          this.$router.push(route);
        }
      }
    }
  }
});

app.mount('#app')
