<template>
  <div class="toolbar">
    <a href="/" class="toolbar-logo">
      <img
        v-if="hostName === 'localhost'"
        alt="TİM"
        src="../assets/logo-tim.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'staging-uyelik.tim.org.tr'"
        alt="AKİB"
        src="../assets/logo-akib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.akib.org.tr'"
        alt="AKİB"
        src="../assets/logo-akib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.baib.gov.tr'"
        alt="BAİB"
        src="../assets/logo-baib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.denib.gov.tr'"
        alt="DENİB"
        src="../assets/logo-denib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.daib.org.tr'"
        alt="DAİB"
        src="../assets/logo-daib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.dkib.org.tr'"
        alt="DKİB"
        src="../assets/logo-dkib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.eib.org.tr'"
        alt="EİB"
        src="../assets/logo-eib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.gaib.org.tr'"
        alt="GAİB"
        src="../assets/logo-gaib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.hib.org.tr'"
        alt="HİB"
        src="../assets/logo-hib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.iib.org.tr'"
        alt="İİB"
        src="../assets/logo-iib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.immib.org.tr'"
        alt="İMMİB"
        src="../assets/logo-immib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.itkib.org.tr'"
        alt="İTKİB"
        src="../assets/logo-itkib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.kib.org.tr'"
        alt="KİB"
        src="../assets/logo-kib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.oaib.org.tr'"
        alt="OAİB"
        src="../assets/logo-oaib.svg"
        class="img-fluid"
      />
      <img
        v-else-if="hostName === 'uyelik.uib.org.tr'"
        alt="UİB"
        src="../assets/logo-uib.svg"
        class="img-fluid"
      />
      <img v-else alt="TİM" src="../assets/logo-tim.svg" class="img-fluid" />
    </a>
    <h1 v-if="isTitleVisible">{{ title }}</h1>
    <div class="call-us">
      <div>SORUNUZ MU VAR?</div>
      <a href="tel:4440846">444 0 846</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title'],
  computed: {
    isTitleVisible () {
      // eslint-disable-next-line eqeqeq
      return this.title != '' && this.title != null
    },
    hostName () {
      return window.location.hostname.toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";
@import "../scss/mixins";

.toolbar {
  background: #fff;
  //border-top: solid 4px $color-brand-primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;

  @include box-shadow();

  .toolbar-logo {
    img {
      max-height: 50px;
    }
  }

  h1 {
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    text-transform: uppercase;
    font-weight: $font-weight-extra-bold;
  }

  .call-us {
    color: #505050;
    font-size: 13px;
    text-align: right;
    line-height: 13px;

    a {
      font-size: 36px;
      line-height: 32px;
      color: #000000;
      text-decoration: none;
      font-weight: 500;
      display: block;
    }
  }
}
</style>
