<template>
  <app-card>
      <div>
        <app-title-desc
          title="TİM İmza Uygulamasını Yükleyin"
          class="primary"
        >
        <div>
          Belgeleri imzalayabilmeniz için TİM İmza Uygulamasının bilgisayaraınıza yüklü ve
          çalışır durumda olması gerekmektedir.  Eğer TİM İmza uygulaması bilgisayarınızda
          yüklü ise uygulamanın çalışır durumda olduğundan enim olun. .
          <br /><br />Eğer TİM İmza uygulaması bilgisayarınızda yüklü değil ise aşağıdaki butona basarak uygumayı
          bilgisayarınıza yükleyebilirsiniz. Bilgisayarınıza kurulum dosyasını
          imndirdikten sonra çift tıklayarak çalıştırıp <b>Kur</b> butonuna basarak kurulumu
          tamamlayabilirsiniz. E-imza uygulamasını çalıştırabilmek için güncel <b>Java</b> çalışma zamanı
          kütüphanesinin bilgisayarınızda yüklü olması gerekmektedir.
        </div>
        </app-title-desc>
        <div class="pt-4">
          <a
            class="btn btn-primary mb-3 me-2"
            :href="`https://kheiron.tim.org.tr/ECSVersionControlProxy/SetupJar/ECSDownloaderWS.jnlp?d=${new Date().getTime()}`"
          >
            TİM İmza Uygulamasını Yükle
          </a>
          <a href="https://java.com/download/" class="btn btn-outline-secondary mb-3 me-2" target="_blank">Java Çalışma Zamanı Kütüphanesini Yükle</a>
        </div>
      </div>
    </app-card>
</template>
